<template>
  <v-dialog
      max-width="60vw"
      v-model="dialogVisible"
      @click:outside="close"
      @keydown.esc="close"
  >
    <v-card max-width="60vw">
      <v-card-text class="pa-5">
        <v-form class="fields"
                ref="form"
                v-model="valid">
          <v-select
            v-model="form.categoryId"
            :items="categories"
            item-value="id"
            item-text="nameRu"
            label="Категория"
            :rules="[v => !!v]"
          />
          <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="form.startDate"
                  label="Дата"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[v => !!v]"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="form.startDate"
                :max="form.endDate"
                @input="menu = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.endDate"
                label="Дата"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[v => !!v]"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="form.endDate"
                :min="form.startDate"
                @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn :loading="loading" text @click="close">Отменить</v-btn>
        <v-btn :loading="loading" @click="download" color="primary">Выгрузить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ExportForm',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        categoryId: null,
        startDate: null,
        endDate: null,
      },
      menu: false,
      menu2: false,
      valid: false,
      loading: false,
    };
  },
  computed: {
    ...mapState('nomenclatures', ['categories']),
  },
  methods: {
    download() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const query = Object.entries(this.form).map(([k, v]) => `${k}=${v}`).join('&');
        this.$reports
          .get(`/api/v1/clever-market-report/get-product-level-name-nomenclature-report-xls?${query}`)
          .then((res) => {
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(res);
            a.download = 'Список товаров с категориями';
            a.click();
            this.loading = false;
            this.close();
          });
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.fields {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}
</style>
