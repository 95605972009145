<template>
  <v-dialog v-model="visible"
            width="800"
            @click:outside="close"
            @keydown.esc="close"
            content-class="category-dialog"
  >
    <v-card>
      <v-card-title class="card-title elevation-3">
        <span class="mr-3">
          {{ editIdLocal ? 'Редактирование категории' : 'Добавить категорию'}}
        </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-4">
        <div v-if="loading" class="d-flex justify-center">
          <v-progress-circular
              :size="20"
              class="align-self-center"
              color="primary"
              indeterminate
              :width="5"
          ></v-progress-circular>
        </div>
        <v-form
            v-else
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-text-field
              v-model="item.nameRu"
              :rules="rules"
              label="Наименование Ру"
              required
              :disabled="loading"
          ></v-text-field>

          <v-text-field
              v-model="item.nameKk"
              :rules="rules"
              label="Наименование КК"
              required
              :disabled="loading"
          ></v-text-field>
          <v-autocomplete
              v-model="item.parentId"
              label="Родительская категория"
              :items="categories"
              :disabled="loading"
              item-text="nameRu"
              item-value="id"
          ></v-autocomplete>
        </v-form>
      </v-card-text>
      <v-card-actions class="category-dialog__actions pb-5"
                      :class="item.hasChild ? 'justify-end' : 'justify-space-between'">
        <v-btn
            v-if="!item.hasChild"
            class="settings"
            @click="openSettings"
            :disabled="!editIdLocal || loading"
            text
        >
          <v-icon>
            mdi-cog
          </v-icon>
          Настройки категории
        </v-btn>
        <div>
          <span v-if="saved" class="success-message">
            Сохранено!
          </span>
          <v-btn
              :disabled="loading"
              class="save"
              @click="save"
              color="#007450"
          >
            Сохранить
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
    <category-settings :visible="settingsOpened"
                       v-if="settingsOpened"
                       :edit-id="editIdLocal"
                       :item="item"
                       @closeSettings="closeSettings"/>
  </v-dialog>
</template>

<script>
import CategorySettings from './CategorySettings.vue';

export default {
  name: 'CategoryDialog',
  components: { CategorySettings },
  props: {
    visible: {
      default: () => false,
    },
    editId: {
      default: null,
    },
  },
  data() {
    return {
      valid: true,
      item: {
        nameRu: '',
        nameKk: '',
        parentId: null,
      },
      rules: [
        (v) => !!v || 'Обязательно поле',
        (v) => (v && v.length > 2) || 'Введите минимально три символа',
      ],
      settingsOpened: false,
      editIdLocal: this.editId,
      loading: false,
      saved: false,
      categoryLoading: false,
    };
  },
  computed: {
    categories() {
      return this.$store.getters['nomenclatures/getCategories'].filter((i) => i.level !== 2);
    },
  },
  created() {
    if (this.editId) this.loadCategory();
  },
  methods: {
    loadCategory() {
      this.loading = true;
      this.$api.get(`/api/v1/wms/categories/${this.editId}`)
        .then((response) => {
          this.editIdLocal = response.id;
          this.item = response;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    closeSettings() {
      this.settingsOpened = false;
    },
    openSettings() {
      this.settingsOpened = true;
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    close() {
      this.reset();
      this.$emit('closed');
    },
    createNewCategory(body) {
      this.loading = true;
      this.$api.post('/api/v1/wms/categories', body)
        .then((response) => {
          this.editIdLocal = response.id;
          this.$emit('saved');
          this.saved = true;
          setTimeout(() => {
            this.saved = false;
          }, 3000);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    editCategory(body) {
      this.$api.put('/api/v1/wms/categories', body).then(() => {
        this.$emit('saved');
      }).finally(() => {
        this.close();
      });
    },
    save() {
      if (this.validate()) {
        const body = {
          ...this.item,
          id: 0,
        };
        if (this.editIdLocal) {
          body.id = this.editIdLocal;
          this.editCategory(body);
        } else {
          this.createNewCategory(body);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.category-dialog {
  &__actions {
    .save {
      color: #FFFFFF;
      border-radius: 18px
    }
    .settings {
      background-color: #ececec;
      color: #000000;
      border-radius: 18px
    }
    .success-message {
      margin-right: 20px;
      color: #007450;
    }
  }
}
</style>
