<template>
  <v-form
      ref="extraFields"
      v-model="extraFieldsValid"
      lazy-validation
      class="mb-3"
  >
    <v-row dense class="d-flex extra-field-item">
      <v-col cols="3">
        <v-text-field v-model="item.field.nameRu"
                      :rules="emptyRule"
                      class="align-self-center" />
      </v-col>
      <v-col cols="2" class="justify-end">
        <v-checkbox :value="item.required"
                    class="justify-end"
                    v-model="item.required"></v-checkbox>
      </v-col>
      <v-col cols="2">
        <v-select v-model="item.field.type"
                  :items="dictionaryList"
                  @change="onChangeType"
                  class="align-self-center"></v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field v-if="item.field.type === 'DICTIONARY'"
                      v-model="dictionaryListItem"
                      append-icon="mdi-plus"
                      :error="error"
                      :error-messages="error ? 'Добавьте значения' : undefined"
                      @click:append="addDictionaryItem"
                      @keypress.enter="addDictionaryItem"
                      class="align-self-center" />
        <div class="dictionary-value-list">
          <v-chip v-for="(item, index) in item.field.dictionaryValueList"
                  :key="item.nameRu + index"
                  close
                  small
                  color="#DBDBDB"
                  text-color="#0B583F"
                  close-icon="mdi-close"
                  @click:close="deleteDictionaryItem(index)"
                  class="custom-chip"
          >
            {{ item.nameRu }}
          </v-chip>
        </div>
      </v-col>
      <v-col v-if="fieldsSaved">
        <div class="success-message">
          Сохранено!
        </div>
      </v-col>
      <v-col v-else cols="2" class="align-self-start mt-3">
        <div v-if="item.editing">
          <v-btn icon class="align-self-center" @click="saveExtraFields">
            <v-icon>
              mdi-check
            </v-icon>
          </v-btn>
          <v-btn icon class="align-self-center" @click="cancelEditing">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div v-else>
          <v-btn v-if="!item.id" icon class="align-self-center" @click="saveExtraFields">
            <v-icon>
              mdi-check
            </v-icon>
          </v-btn>
          <v-btn icon class="align-self-center" @click="deleteExtraItem">
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

export default {
  name: 'ExtraFieldItem',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    categoryId: {
      type: Number,
    },
  },
  data() {
    return {
      dictionaryList: [
        'STRING',
        'STRING_LIST',
        'DICTIONARY',
        'NUMBER',
        'DECIMAL',
      ],
      emptyRule: [
        (v) => !!v || 'Обязательно поле',
      ],
      extraFieldsValid: true,
      dictionaryListItem: null,
      itemLocal: JSON.parse(JSON.stringify(this.item)),
      editing: false,
      fieldsSaved: false,
      error: false,
    };
  },
  watch: {
    item: {
      handler(val) {
        this.$set(this.item, 'editing', JSON.stringify(val) !== JSON.stringify(this.itemLocal));
      },
      deep: true,
    },
  },
  methods: {
    onChangeType() {
      if (this.item.field.type !== 'DICTIONARY') {
        this.item.field.dictionaryId = null;
        this.item.field.dictionaryValueList = null;
      }
    },
    addDictionaryItem() {
      if (!this.dictionaryListItem) return;
      if (!this.item.field.dictionaryValueList) {
        this.$set(this.item.field, 'dictionaryValueList', []);
      }
      this.item.field.dictionaryValueList.push({
        nameRu: this.dictionaryListItem,
        nameKk: this.dictionaryListItem,
      });
      this.dictionaryListItem = null;
    },
    deleteDictionaryItem(index) {
      const temp = [...this.item.field.dictionaryValueList];
      temp.splice(index, 1);
      this.item.field.dictionaryValueList = temp;
    },
    cancelEditing() {
      this.$emit('update:item', { ...JSON.parse(JSON.stringify(this.itemLocal)), editing: false });
    },
    saveExtraFields() {
      if (this.$refs.extraFields.validate()
          && (this.item.field.type !== 'DICTIONARY'
          || (this.item.field.dictionaryValueList
            && this.item.field.dictionaryValueList.length > 0))
      ) {
        if (this.item.id) {
          this.editExtraFields();
        } else {
          this.createExtraFields();
        }
        this.error = false;
      } else {
        this.error = true;
      }
    },
    editExtraFields() {
      this.$api.put('/api/v1/wms/category/fields', {
        ...this.item,
        field: {
          ...this.item.field,
          nameKk: this.item.field.nameRu,
        },
      })
        .then((response) => {
          this.$emit('update:item', { ...response, editing: false });
          this.showExtraFieldsSavingMessage();
        });
    },
    createExtraFields() {
      this.$api.post('/api/v1/wms/category/fields', {
        ...this.item,
        categoryId: this.categoryId,
        field: {
          ...this.item.field,
          nameKk: this.item.field.nameRu,
        },
      })
        .then((response) => {
          this.$emit('update:item', { ...response, editing: false });
          this.showExtraFieldsSavingMessage();
        });
    },
    deleteExtraItem() {
      this.$store.dispatch('tools/showConfirm', {
        title: 'Подтвердите',
        text: 'Вы действительно хотите удалить?',
      }).then(() => {
        if (this.item.id) {
          this.deleteExtraField();
        } else {
          this.$emit('deleteItem');
        }
      }).catch(() => {});
    },
    deleteExtraField() {
      this.$api.delete(`/api/v1/wms/category/fields/${this.item.id}`)
        .then(() => {
          this.$emit('deleteItem');
        });
    },
    showExtraFieldsSavingMessage() {
      this.fieldsSaved = true;
      setTimeout(() => {
        this.fieldsSaved = false;
        this.$emit('update:item', { ...this.item, editing: false });
      }, 3000);
    },
  },
};
</script>

<style lang="scss">
.extra-field-item {
  .dictionary-value-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
  }
  .custom-chip {
    margin-left: 4px;
    margin-top: 8px;
  }
  .v-input__slot {
    justify-content: center;
  }
  .success-message {
    align-self: center;
    margin-right: 20px;
    color: #007450;
    padding-top: 12px;
    margin-top: 4px;
  }
}
</style>
