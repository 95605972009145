<template>
  <div class="categories">
    <v-btn rounded color="primary" large
           @click="$emit('add-category')"
           v-if="permissions.create"
    >
      <v-icon left>
        mdi-plus
      </v-icon>
      Категория
    </v-btn>
    <v-container fluid style="padding: 0;max-height: calc(100vh - 270px); overflow: scroll;">
      <v-row class="category-header">
        <v-col cols="7" style="text-align: left">
          <span> Категория </span>
        </v-col>
        <v-col cols="4" style="text-align: center">
          <span>
            Количество
          </span>
        </v-col>
        <v-col cols></v-col>
      </v-row>
      <v-row>
        <v-treeview
          :items="items"
          item-key="id"
          item-children="children"
          item-text="nameRu"
          color="primary"
          :open="active"
          :active="active"
          transition
          hoverable
          class="elevation-1 mt-5 categories__tree"
        >
          <template slot="label" slot-scope="{ item }">
            <div class="d-flex justify-space-between row--dense category-item">
              <div class="category-item__text col-9"
                   @click.prevent="handleTreeClick(item)">
                {{ item.nameRu }}
              </div>
              <div class="d-flex justify-space-between col-3 category-item__extra">
                <span class="category-item__extra--count"> {{item.productCount }}</span>
                <v-btn icon @click.prevent="edit(item)" v-if="permissions.update">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
    <!--            <v-btn icon @click.prevent="changeStatus(item)">-->
    <!--              <v-icon v-if="item.status === 'DISABLED'">-->
    <!--                mdi-eye-->
    <!--              </v-icon>-->
    <!--              <v-icon v-else>-->
    <!--                mdi-eye-off-->
    <!--              </v-icon>-->
    <!--            </v-btn>-->
              </div>
            </div>
          </template>
        </v-treeview>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CategoryTree',
  props: ['modified'],
  data() {
    return {
      active: [],
      items: [],
      category: null,
    };
  },
  created() {
    this.fetchCategories();
    this.$loading(true);
  },
  watch: {
    modified: {
      handler(val) {
        if (val && val > 0) {
          this.fetchCategories();
        }
      },
    },
  },
  computed: {
    ...mapState(['user']),
    permissions() {
      const page = this.user.availablePages[this.$route.name];
      return page || {};
    },
  },
  methods: {
    fetchCategories() {
      this.$api.get('/api/v1/wms/categories').then((response) => {
        const resp = [...response];
        this.$store.dispatch('nomenclatures/actionSetCategories', resp);
        const child = response.filter((i) => i.parentId);
        child.forEach((i) => {
          const find = resp.find((x) => x.id === i.parentId);
          if (find) {
            if (!find.children) {
              find.children = [];
            }
            find.children.push(i);
          }
        });
        this.items = resp.filter((i) => !i.parentId);
      }).finally(() => {
        this.$loading(false);
      });
    },
    handleTreeClick(item) {
      if (this.active.indexOf(item.parentId) !== -1) {
        this.active.push(item.id);
      } else {
        this.active = [item.id];
      }
      this.$emit('set-category', item.id);
    },
    edit(item) {
      this.$emit('edit-category', item.id);
    },
    changeStatus(item) {
      let method;
      if (item.status === 'DISABLED') {
        method = 'enable';
      } else {
        method = 'disable';
      }
      this.$api.post(`/api/v1/wms/categories/${method}/${item.id}`).then(() => {
        this.fetchCategories();
      });
    },
  },
};
</script>

<style lang="scss">
.categories {
  &__tree {
    width: 100%;

    .category-item {
      cursor: pointer;
      &__text {
        white-space: pre-wrap;
        width: 100%;
        height: 100%;
        align-self: center;
      }
      &__extra {
        &--count {
          align-self: center;
          margin-right: 10px;
        }
      }
    }
  }
}
.category-header {
  height: 48px;
  background-color: #CFD0C9;
  margin-top: 20px;
  margin-bottom: -31px;
  border-radius: 3px 3px 0px 0px;

  span {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #7E8271;
  }
}

</style>
