<template>
  <div>
    <ProductCardModal
      :visible="showProductCardModal"
      :product="productModal"
      @closed="handleCloseDialog"
    />
    <v-btn
      @click="handleDialog(true)"
       color="#9DC033"
       x-large
       depressed
       dark
       width="260"
       height="55"
       style="visibility: hidden"
    >
      <v-icon left x-large> mdi-plus </v-icon>
      Товар </v-btn>
    <crm-table-pageable-new
      :headers="headers"
      :config="config"
      :data="products"
      :loaded-options="options"
      :total-elements="totalElements"
      :total-pages="totalPages"
      :extraActions="extraActions"
      @handleActions="handle"
      @extraAction="handleExtraAction"
      @onChangeData="onChangeData"/>
    <ExportForm
      v-if="dialogVisible"
      :dialog-visible="dialogVisible"
      @close="handleExtraActionClose"
    />
  </div>
</template>

<script>

// import ProductDialog from '@/views/wms/nomenclatures/ProductDialog.vue';
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import ProductCardModal from '@/components/ProductCardModal.vue';
import ExportForm from './product/ExportForm.vue';

export default {
  name: 'Products',
  components: {
    ExportForm,
    ProductCardModal,
    'crm-table-pageable-new': crmTablePageableNew,
  },
  props: ['categoryId'],
  data() {
    return {
      loading: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          value: 'productNomenclatureId', sortable: false, text: 'Номенклатура', type: 'defaultItem',
        },
        {
          value: 'supplier.name', sortable: false, text: 'Продавец', type: 'defaultItem',
        },
        {
          value: 'name', sortable: false, text: 'Наименование', type: 'defaultItem',
        },
        {
          value: 'nomenclatureCode', sortable: false, text: 'Артикул цвета', type: 'defaultItem',
        },
        {
          value: 'barcode', sortable: false, text: 'Штрихкод', type: 'defaultItem',
        },
        {
          value: 'color.nameRu', sortable: false, text: 'Цвет', type: 'defaultItem',
        },
        {
          value: 'brand', sortable: false, text: 'Бренд', type: 'defaultItem',
        },
        {
          value: 'published', sortable: false, text: 'Опубликовано', type: 'tableBool',
        },
        {
          value: 'imageCount', sortable: false, text: 'Количество фото', type: 'defaultItem',
        },
      ],
      products: [],
      editId: -1,
      itemDelete: {},
      currentCategory: {
        id: null,
      },
      config: {
        search: false,
        pageable: true,
      },
      totalElements: 0,
      totalPages: 0,
      url: '/api/v1/wms/products',
      options: {
        page: 1,
        size: 10,
      },
      showProductCardModal: false,
      productModal: {},
      extraActions: [
        {
          type: 'reports',
          url: '/api/v1/clever-market-report/get-product-level-name-nomenclature-report-xls',
          action: 'export',
          title: 'Выгрузить список товаров с категориями в XLS',
          fileName: 'Продавцы с товарами',
        },
      ],
      dialogVisible: false,
    };
  },
  watch: {
    categoryId: {
      handler(val) {
        if (val && val !== this.currentCategory.id) {
          this.loading = true;
          this.currentCategory = { id: val };
          this.onChangeData(this.options);
        }
      },
      immediate: true,
    },
  },
  computed: {
    formTitle() {
      return this.editId === -1 ? 'Новый товар' : 'Редактировать';
    },
    categories() {
      return this.$store.getters['nomenclatures/getCategories'];
    },
  },
  methods: {
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.options = { ...resultOptions };
      this.getProducts();
    },
    getProducts() {
      const params = this.options;
      const formattedUrl = this.url;
      this.$loading(true);
      this.$api.get(formattedUrl, {
        params: {
          categoryId: this.categoryId,
          page: params.page - 1,
          size: params.size,
          sort: params.sort,
        },
      }).then((response) => {
        this.totalElements = response.totalElements;
        this.totalPages = response.totalPages;
        this.products = response.content.map((i) => {
          // eslint-disable-next-line no-param-reassign
          i.name = `${i.brand} / ${i.equipment.join(', ')}`;
          return i;
        });
        this.$store.dispatch('nomenclatures/actionSetProducts', Object.freeze(this.products));
      }).finally(() => {
        this.loading = false;
        this.$loading(false);
      });
    },
    editItem(item) {
      this.editId = item.id;
      this.handleDialog(false);
    },

    deleteItem(item) {
      this.editId = item.id;
      this.itemDelete = item;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$api.delete(`/api/v1/wms/products/${this.editId}`).then(() => {
        this.getProducts(this.categoryId.id);
      }).finally(() => {
        this.closeDelete();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editId = -1;
      });
    },
    async handle(event) {
      if (event.type === 'info') {
        await this.getProductCardById(event.item);
        this.showProductCardModal = true;
      }
    },
    async getProductCardById(item) {
      const { productCardId, productNomenclatureId, id } = item;
      const response = await this.$api.get(`/api/v1/public/products/product-card/${productCardId}`);
      if (response) {
        const find = response.nomenclatureList.find((j) => j.id === productNomenclatureId);
        if (find) {
          const product = find.productList.find((i) => i.id === id);
          if (product) {
            this.productModal = {
              ...product,
              imageUrlList: find.imageUrlList,
              supplierName: response.supplier.name,
              productName: response.nameRu,
              status: response.status,
              description: response.description,
              nomenclatureCode: find.nomenclatureCode,
              color: find.color.nameRu,
              additionalColor: find.additionalColor,
              extraFields: response.extraFields,
            };
          }
        }
      }
      return response;
    },
    handleCloseDialog() {
      this.visible = false;
      this.showProductCardModal = false;
      this.productModal = {};
    },
    dialogHandler() {
      this.editId = -1;
      this.dialog = false;
    },
    getCategoryName(id) {
      return this.categories.find((i) => i.id === id).nameRu;
    },
    handleDialog(val) {
      if (this.categoryId) {
        this.$router.replace({
          name: 'nomenclature/add-product',
          query: {
            isNew: val,
            categoryId: this.categoryId.id,
            productId: this.editId,
          },
        });
      } else {
        alert('выберите категорию');
      }
    },
    handleExtraAction(action) {
      if (action === 'export') {
        this.dialogVisible = true;
      }
    },
    handleExtraActionClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss">

</style>
