<template>
  <v-container fluid>
    <category-dialog :visible="categoryDialogVisible"
                     v-if="categoryDialogVisible"
                     @closed="categoryClosed"
                     @saved="modified++"
                     :edit-id="editId"/>
<!--    <product-dialog :visible="productDialogVisible" :category="currentCategoryId"-->
<!--                    @closed="productDialogVisible = false"/>-->
    <v-row>
      <v-col cols="4">
        <category-tree @add-category="addCategory" @edit-category="editCategory"
                       @set-category="setCategoryId" :modified="modified"/>
      </v-col>
      <v-col cols="8">
        <products :categoryId="currentCategoryId"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CategoryDialog from '@/views/wms/nomenclatures/CategoryDialog.vue';
import Products from '@/views/wms/nomenclatures/Products.vue';
// import ProductDialog from '@/views/wms/nomenclatures/ProductDialog.vue';
import CategoryTree from '@/views/wms/nomenclatures/CategoryTree.vue';

export default {
  components: {
    Products, CategoryTree, CategoryDialog,
  },
  data() {
    return {
      categoryDialogVisible: false,
      currentCategoryId: null,
      modified: 0,
      editId: null,
    };
  },

  methods: {
    addCategory() {
      this.categoryDialogVisible = true;
    },
    setCategoryId(val) {
      this.currentCategoryId = val;
    },
    editCategory(id) {
      this.editId = id;
      this.categoryDialogVisible = true;
    },
    categoryClosed() {
      this.categoryDialogVisible = false;
      this.editId = null;
    },
  },
};
</script>

<style scoped>
h1 {
  height: 200vh;
}
</style>
