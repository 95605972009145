<template>
  <v-dialog v-model="visible"
            width="1200"
            @click:outside="close"
            @keydown.esc="close"
            content-class="category-settings-dialog"
  >
    <v-card>
      <v-card-title class="card-title elevation-3">
        <span class="mr-3">Категория: {{ item.nameRu }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-4 category-settings-dialog__main">
        <div class="required-fields" v-if="configs">
          <h2 class="mb-4">Обязательные поля</h2>
          <div v-if="configsLoading" class="d-flex justify-center">
            <v-progress-circular
                :size="20"
                class="align-self-center"
                color="primary"
                indeterminate
                :width="5"
            ></v-progress-circular>
          </div>
          <v-form
              v-else
              ref="requiredFields"
              v-model="requiredFieldsValid"
              class="mb-3"
              lazy-validation
          >
            <div>
              <v-row dense class="align-content-center">
                <v-col cols="6">
                  Наименование поля
                </v-col>
                <v-col cols="3">
                  Наличие
                </v-col>
                <v-col cols="3">
                  Многозначное
                </v-col>
              </v-row>
              <v-row dense justify="center">
                <v-col cols="6" class="align-self-center">
                  Дополнительный цвет
                </v-col>
                <v-col cols="3" class="align-self-center">
                  <v-checkbox v-model="configs.additionalColor"
                              class="">
                  </v-checkbox>
                </v-col>
                <v-col cols="3"></v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6" class="align-self-center">
                  Размер
                </v-col>
                <v-col cols="3" class="align-self-center">
                  <v-checkbox v-model="configs.size"
                              class="">
                  </v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox v-model="configs.type"
                              :disabled="!configs.size"
                              class="align-self-center">
                  </v-checkbox>
                </v-col>
              </v-row>
            </div>
          </v-form>
          <div class="required-fields__actions d-flex justify-end">
            <span v-if="requiredFieldsSaved" class="success-message">
              Сохранено!
            </span>
            <v-btn
                class="save"
                :disabled="requiredFieldsSaved || configsLoading"
                @click="saveRequiredFields"
                color="#007450"
            >
              Сохранить
            </v-btn>
          </div>
        </div>
        <div class="extra-fields">
          <h2 class="mb-4">Дополнительные поля</h2>
          <hint-text class="pl-0">
            <span>
              <b>Внимание!</b> Изменения будут <b>применены сразу</b> после нажатия на
              «<v-icon> mdi-check </v-icon>».
            </span>
          </hint-text>
          <div v-if="extraFieldsLoading" class="d-flex justify-center">
            <v-progress-circular
                :size="20"
                class="align-self-center"
                color="primary"
                indeterminate
                :width="5"
            ></v-progress-circular>
          </div>
            <div>
              <v-row dense class="align-content-center">
                <v-col cols="3">
                  Наименование поля
                </v-col>
                <v-col cols="2">
                  Обязательность
                </v-col>
                <v-col cols="2">
                  Тип поля
                </v-col>
                <v-col cols="3">
                  Значения справочника
                </v-col>
                <v-col cols="2">
                  Действие
                </v-col>
              </v-row>
              <div v-for="(item, index) in extraFieldsData"
                   :key="getUniqKey(index)">
                <extra-field-item :category-id="editId"
                                  :item="item"
                                  v-on:update:item="saveItem(index, $event)"
                                  @saveItem="(data) => saveItem(index, data)"
                                  @deleteItem="deleteItem(index)"/>
              </div>
              <div>
                <v-btn text rounded :ripple="false" @click="addItem">
                  + Добавить
                </v-btn>
              </div>
            </div>
        </div>
      </v-card-text>
      <v-card-actions class="category-settings-dialog__actions justify-end pb-5 pt-4 elevation-3">
        <v-btn
            class="close"
            @click="close"
            text
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HintText from '@/components/HintText.vue';
import ExtraFieldItem from './ExtraFieldItem.vue';

export default {
  name: 'CategorySettings',
  components: {
    ExtraFieldItem,
    HintText,
  },
  props: {
    visible: {
      default: () => false,
    },
    editId: {
      default: null,
    },
    item: {
      default: () => {},
    },
  },
  data() {
    return {
      requiredFieldsValid: true,
      extraFieldsValid: true,
      configs: null,
      extraFieldsData: [],
      dictionaryListItem: null,
      configsLoading: false,
      extraFieldsLoading: false,
      requiredFieldsSaved: false,
    };
  },
  watch: {
    'configs.size': {
      handler(val) {
        if (!val) {
          this.$set(this.configs, 'type', false);
        }
      },
    },
  },
  created() {
    this.loadCategoryConfigs();
    this.loadExtraFields();
  },
  methods: {
    getUniqKey(index) {
      return new Date().getTime() + index;
    },
    showRequiredFieldsSavingMessage() {
      this.requiredFieldsSaved = true;
      setTimeout(() => {
        this.requiredFieldsSaved = false;
      }, 3000);
    },
    saveRequiredFields() {
      this.$api.put('/api/v1/wms/category/config', {
        ...this.configs,
        categoryId: this.editId,
        collection: false,
        type: this.configs.type ? 'MANY' : 'ONE',
      })
        .then(() => {
          this.showRequiredFieldsSavingMessage();
        });
    },
    loadExtraFields() {
      if (this.editId) {
        this.extraFieldsLoading = true;
        this.$api.get('/api/v1/wms/category/fields', {
          params: {
            categoryId: this.editId,
            page: 0,
            scope: 'EXTRA',
            size: 1000,
          },
        }).then((response) => {
          this.extraFieldsData = response.content.map((i) => ({
            ...i,
            editing: false,
          }));
        }).finally(() => {
          this.extraFieldsLoading = false;
        });
      }
    },
    loadCategoryConfigs() {
      if (this.editId) {
        this.configsLoading = true;
        this.$api.get('/api/v1/wms/category/config', { params: { categoryId: this.editId } })
          .then((response) => {
            this.configs = {
              ...response,
              type: response.type === 'MANY',
            };
          }).finally(() => {
            this.configsLoading = false;
          });
      }
    },
    close() {
      this.$emit('closeSettings');
    },
    addItem() {
      this.extraFieldsData.push({
        field: {
          nameKk: '',
          nameRu: '',
          type: 'STRING',
        },
        required: true,
        editing: true,
        scope: 'EXTRA',
      });
    },
    saveItem(index, data) {
      const temp = [...this.extraFieldsData];
      this.extraFieldsData = temp.map((i, itemIndex) => {
        if (index === itemIndex) {
          return {
            ...i,
            ...data,
          };
        }
        return i;
      });
    },
    deleteItem(index) {
      const temp = [...this.extraFieldsData];
      temp.splice(index, 1);
      this.extraFieldsData = temp;
    },
  },
};
</script>

<style lang="scss">
.category-settings-dialog {
  overflow-y: hidden;
  .container {
    padding: 0 !important;
    .elevation-2 {
      box-shadow: none !important;
    }
  }
  &__actions {
    .save {
      color: #FFFFFF;
      border-radius: 18px
    }
    .close {
      background-color: #ececec;
      color: #000000;
      border-radius: 18px
    }
  }

  &__main {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    .required-fields {
      padding: 20px;
      border: 1px solid #9A9D9C;
      border-radius: 10px;
      margin-bottom: 20px;

      .row {
        height: 40px!important;
      }

      &__actions {
        .save {
          color: #FFFFFF;
          border-radius: 18px
        }
        .success-message {
          align-self: center;
          margin-right: 20px;
          color: #007450;
        }
      }
    }
    .extra-fields {
      padding: 20px;
      border: 1px solid #9A9D9C;
      border-radius: 10px;

      &__actions {
        .save {
          color: #FFFFFF;
          border-radius: 18px
        }
      }
    }
  }
}
</style>
