<template>
  <v-dialog
    v-model="dialogVisible"
    @click:outside="$emit('closed')"
    @keypress.esc.prevent="$emit('closed')"
    :scrollable="false"
    width="375"
  >
    <div v-if="loaded" class="product-card-modal">
      <div class="header">
        <v-btn class="header__close" fab dark text @click="$emit('closed')">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <div class="content">

        <v-carousel>
          <v-carousel-item
            v-for="(item,i) in product.imageUrlList"
            :key="i"
            :src="item.src"
            reverse-transition="fade-transition"
            transition="fade-transition"
          ></v-carousel-item>
        </v-carousel>
        <div class="d-flex flex-column description">
          <span class="description__product-name">
            {{product.productName}}
          </span>
          <span class="description__price">
            {{product.price}} <span class="description__price old-price">
            {{product.priceWithDiscount !== product.price ? product.priceWithDiscount : ''}} </span>
          </span>
          <span class="description__supplier-name">
            {{product.supplierName}}
          </span>
          <span class="description__nomenclature-code">
            Артикул {{product.nomenclatureCode}}
          </span>
          <span class="description__color">
            Цвет <span> {{product.color}} </span>
          </span>
          <span class="description__additional-color"
                v-if="product.additionalColor && product.additionalColor.length > 0">
            Дополнительные цвета: {{product.additionalColor.join(',')}}
          </span>
          <div class="description__size" v-if="product.size">
            <span>
              {{product.size}}
            </span>

          </div>
          <div class="description__tabs">
            <span :class="['description__tabs-title', {'active': activeTab === 0}]"
                  @click="activeTab = 0">Описание</span>
            <span :class="['description__tabs-title',{'active': activeTab === 1}]"
                  @click="activeTab = 1">Характеристика</span>
          </div>
          <div v-if="activeTab === 0" class="description__tabs__info"
               v-html="product.description"></div>
          <div v-else-if="activeTab === 1" class="description__tabs__specification">
            <div class="description__tabs__specification-item"
                 v-for="(item, index) of product.extraFields" :key="index"
            >
              <span>
                {{item.field.nameRu}}
              </span>
              <span>
                {{getExtraFieldText(item)}}
              </span>
            </div>
          </div>
        </div>
      </div>

    </div>

  </v-dialog>
</template>

<script>
export default {
  name: 'ProductCardModal',
  props: {
    visible: {
      default: false,
    },
    product: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: false,
      loaded: false,
      activeTab: 0,
    };
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        this.dialogVisible = val;
        if (val) {
          this.init();
        }
      },
    },
  },
  methods: {
    async init() {
      if (this.product.imageUrlList && this.product.imageUrlList.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for await (const image of this.product.imageUrlList) {
          const index = this.product.imageUrlList.findIndex((i) => i === image);
          if (index > -1) {
            const src = await this.getImageByPath(image.path);
            const current = {
              ...image,
              src,
            };
            this.$set(this.product.imageUrlList, index, current);
          }
        }
      }
      this.loaded = true;
    },
    async getImageByPath(path) {
      // eslint-disable-next-line no-return-await
      return await this.$image
        .get(`/image/api/image/${encodeURIComponent(path)}?size=M`)
        .then((res) => res.data);
    },
    getExtraFieldText(item) {
      if (item.field.type === 'DICTIONARY') {
        const find = item.field.dictionaryValueList.find((i) => i.id === item.value);
        return find.nameRu;
      }
      if (Array.isArray(item.value)) {
        return item.value.join(',');
      }
      return item.value.toString();
    },

  },
};
</script>

<style scoped lang="scss">
.product-card-modal {
  width: 375px;
  height: 812px;
  background: #F4F4F4;
  position: relative;
  overflow: scroll;
  padding-bottom: 30px;
  &::-webkit-scrollbar {
    display: none;
  }
  .header {
    position: fixed;
    width: 375px;
    max-width: inherit;
    z-index: 9;
    height: 60px;
    background:
      radial-gradient(112.11% 156.86% at 15.07% -13.43%, #045530 0%, #30B478 0.01%, #1C0E35 100%);
    &__close {
      position: absolute;
      right: 0;

    }
  }
  .content {
    padding-top: 60px;
  }
  .description {
    padding: 16px 16px 0;
    row-gap: 12px;
    color: #000000;
    font-style: normal;
    &__product-name {
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      font-feature-settings: 'pnum' on, 'lnum' on;
    }
    &__price {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;

      color: #F15B3B;

      .old-price {
        text-decoration-line: line-through;

        color: #666666;
      }
    }
    &__supplier-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      font-feature-settings: 'pnum' on, 'lnum' on;
    }
    &__nomenclature-code {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      position: relative;
      padding-bottom: 8px;
      color: #B6B6B6;
      &:before {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        background: #D7D7D7;
      }
    }
    &__color {
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;

      span:last-child {
        font-weight: 600;
      }
    }
    &__size {
      margin-bottom: 12px;
      span {
        background: #FFFFFF;
        border-radius: 12px;
        width: 50px;
        height: 20px;
        padding: 12px;
      }
    }
    &__tabs {
      &:before {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        top: 0;
        background: #D7D7D7;

      }
      padding-top: 30px;
      position: relative;
      display: flex;
      justify-content: space-evenly;
      &-title {
        position: relative;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        padding-bottom: 10px;
        &:hover {
          cursor: pointer;
        }
      }
      .active {
        font-weight: 600;
        &:before {
          content: "";
          width: 100%;
          height: 1px;
          position: absolute;
          background: #000000;
          bottom: 0;
        }
      }
      &__specification {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        &-item {
          display: flex;
          column-gap: 5px;
          justify-content: space-between;
          align-items: center;
          span:first-child {
            flex: 1 1 50%;
            text-align: start;
          }
          span:last-child {
            flex: 1 1 50%;
            text-align: end;
          }
        }
      }
    }
    //&__info {

    //  font-weight: 300;
    //  font-size: 14px;
    //  line-height: 16px;
    //}
  }

}

</style>
